.logo-card {
    background-color: transparent;
    /* Invisible background */
    border: none;
    /* Remove any default border */
    box-shadow: none;
    /* Remove shadow if any */
    display: flex;
    /* Align logo in the center if needed */
    justify-content: center;
    align-items: center;
    padding: 0;
    /* Adjust padding as needed */
}

.image.is-64x64 img {
    max-width: 100%;
    /* Make sure the image fits properly */
    height: auto;
}

.button2 {
    width: 125px;
    /* Optional: Set a consistent button width */
    font-size: 1.2rem;
    /* Optional: Increase font size */
    border-radius: 5px;
    /* Optional: Add rounded corners */
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it appears above other content */
}

.loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top-color: #4caf50;
    /* Adjust loader color */
    border-radius: 50%;
    width: 50px;
    /* Size of loader */
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.thank-you-container {
    height: 100vh;
    background-color: rgb(236, 236, 17);
    /* Yellow background */
    display: flex;

    align-items: center;
    text-align: center;
    justify-content: center;
    text-align: center;
    text-align: center;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    overflow-y: auto;

}

.thank-you-content {
    padding: 2rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 500px;
}

.thank-you-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.thank-you-text {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.cta-button {
    background-color: #ffcc00;
    /* Yellow button */
    color: white;
    padding: 1rem 2rem;
    border-radius: 5px;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #f3df06;
    /* Darker yellow for hover effect */
}

select:focus {
    border-color: #3298dc;
    box-shadow: 0 0 0 0.125em rgba(50, 152, 220, 0.25);
}

select[multiple] {
    height: auto;
    /* Adjust height for better multi-select appearance */
}